import AppMenuBar from './AppMenuBar';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { TextField, Button, Grid, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "./auth/AuthProvider";
import va from '@vercel/analytics';
import jsonp from 'jsonp';
import queryString from 'query-string';
import Link from '@mui/material/Link';

export default function Placeholder(props) {
    const navigate = useNavigate();
    const { user } = useAuth()
    const [email, setEmail] = React.useState("")
    const [signUp, setSignUp] = React.useState(false)
    const [error, setError] = React.useState(false)
    // const [blogs, setBlogs] = React.useState(null)


    const handleBookdemo = (event) => {
        let bookDemoUrl = "https://calendly.com/neum-ai/neum-ai-demo?month=2023-12"
        window.open(bookDemoUrl, '_blank');
    };

    const subscribeToNewsLetter = () => {
        const formData = {
          EMAIL: email
        };
        jsonp(`https://neum.us21.list-manage.com/subscribe/post-json?u=9a529c3f3ce714958655ce819&amp;id=519485f301&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
          console.log('err:', err);
          console.log('data:', data);
          if (data['result'] == "success"){
            setSignUp(true)
            setError(false)
          }
          if(data['result'] == "error"){
            setError(true)
          }
        });
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

  return (
    <div>
      <AppMenuBar isloginpage={"false"}>
         <div style={{ textAlign: 'center' }} className="min-h-screen bg-gray-900 p-10 lg:p-20">
            <Typography variant="h4"> 
                    Neum AI dashboard coming early 2024
            </Typography>
                
            {/* <div className="col-span-1 text-left text-gray-300 text-3xl font-semibold border-b border-gray-300/30 pb-2">Oh, hello!</div> */}
            {/* <Divider className="bg-gray-300"/> */}
            <br/>
            <div className="max-w-[100%]">
                <Typography variant="h5"> 
                    File management, Audit logs, Role-Based Access Control, and more.
                </Typography>
                <Typography variant="subtitle1"> 
                    Take a look at our  <Link sx={{ "&:hover": {color: "#489de9",}}} color="inherit"  target="_blank" href="https://docs.neum.ai/platform-apis/endpoint/create-a-pipeline">API reference</Link> in the meantime
                </Typography>
                <br/><br/><br/>
                <div style={{ display: 'flex', justifyContent: 'center', marginRight:'20px', marginLeft:'20px' }}>
                    <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={5} style={{ display: 'flex', justifyContent: 'center',  height: '56px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleBookdemo}
                                fullWidth
                                style={{ height: '56px' }}
                            >
                                Book Demo
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={1} style={{ textAlign: 'center' }}>
                            <Typography variant="body1">or</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5} style={{ display: 'flex', justifyContent: 'center', height: '56px' }}>
                            <Grid container>
                                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    {!signUp && <>
                                        <TextField
                                            label="Email"
                                            variant="outlined"
                                            value={email}
                                            fullWidth
                                            onChange={handleEmailChange}
                                            style={{ height: '56px', borderRadius: '4px 0 0 4px', marginRight: '-1px' }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={subscribeToNewsLetter}
                                            style={{ height: '56px', borderRadius: '0 4px 4px 0' }}
                                        >
                                            Subscribe
                                        </Button>
                                    </>}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    {error && <Alert variant="outlined" severity="error">Please check the inputted email and try again.</Alert>}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    {signUp && <Alert variant="outlined" severity="success">Thanks for joining our newsletter!</Alert>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
      </AppMenuBar>
    </div>
  );
};