import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MuiDrawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MuiAppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

import { styled, useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CreateIcon from '@mui/icons-material/Create';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { isMobile } from 'react-device-detect';

import { useAuth } from "./auth/AuthProvider";
import va from '@vercel/analytics';

const pages = ['Home', 'Create', 'Pipelines', 'Search', 'Settings']
const icons = [<HomeIcon />, <CreateIcon />, <CheckCircleIcon />, <ManageSearchIcon />, <SettingsIcon />];
const navItems = [
      {
        name:"Docs",
        href:"https://docs.neum.ai/",
        style:"text",
        target:"_blank"
      },
      {
        name:"GitHub",
        href:"https://github.com/NeumTry/NeumAI",
        style:"text",
        target:"_blank"
      },
      {
        name:"Discord",
        href:"https://discord.gg/mJeNZYRz4m",
        style:"text",
        target:"_blank"
      },
      {
        name:"Book Demo",
        href:"https://calendly.com/neum-ai/neum-ai",
        style:"text",
        target:"_blank"
      },
    ]

    const drawerWidth =  200;
    const openedMixin = (theme) => ({
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
      borderWidth:"0"
    });
    
    const closedMixin = (theme) => ({
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
      borderWidth:"0"
    
    });
    
    const DrawerHeader = styled('div')(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }));
    
    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open, isloginpage}) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        marginLeft: isloginpage === "true" ? 0 : drawerWidth,
        width: isloginpage === "true" ? `100%` : `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));
    
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
      ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
      }),
    );

    
const AppMenuBar = ({ isloginpage, ismobile, children }) => {
  
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const handleOnClick = (page) => {
      page = page.toLowerCase()
      let properties =  {
          date: (new Date()).toUTCString(),
          user_id:user?.id,
          left_bar_icon:page
      }
      va.track("Clicked left bar nav icon", properties)
      if(page == "home")
          page = ""
      if(page == "pipelines")
          page = "mypipelines"
      navigate(`/${page}`);
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar isloginpage={isloginpage} position="fixed" open={false} >
      <Toolbar className='bg-gray-800'>
      <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
        </IconButton>
            <img style={{width:"100px"}} src="/Logo_White.svg"></img>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1}}>
            </Typography>
            {!Boolean(ismobile) &&
            <div className="flex gap-x-6 lg:gap-x-6 sm:gap-x-6 text-white font-semibold">
                {!isMobile && navItems.map((item) => (
                  <Link sx={{ "&:hover": {
                    color: "#489de9",
                }}} color="inherit"  underline="none" key={item.name} href={item.href} onClick={()=>{
                    if(item.href == null) document.getElementById(item.target).scrollIntoView({behavior:"smooth"})
                  }} target={item.target}>
                    {item.name}
                  </Link>
                ))}
              {/* {user && <Link sx={{ "&:hover": { color: "#489de9"}}} color="inherit" underline="none" key={"signout"} href="" onClick={signOut} target={"target"}> Sign out </Link>} */}
              </div>
              }
          </Toolbar>
      </AppBar>
      {/* {isloginpage === "false" &&
        <Drawer variant="permanent" open={open}>
          <DrawerHeader sx={{backgroundColor:"#1f2937"}}>
              <Typography variant="h6"  sx={{ color: "white"}}>
                  Menu
              </Typography>
              <Typography variant="h7" component="div" sx={{ flexGrow: 0.5}}>
              </Typography>
            <IconButton sx={{color:"white"}} onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Paper square={true} style={{ backgroundColor: '#030712', height:"100%"}}>
          <Divider />
          <List>
            {pages.map((text, index) => (
              <ListItem key={text} disablePadding>
                <Tooltip title={open?'':text} arrow placement='right'>
                <ListItemButton
                  onClick={(event) => handleOnClick(text)}
                  sx={{
                    px: 2.5,
                    '&:hover': {
                      background: "#07112c",
                      }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:"#1976d2",
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                      {icons[index]}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ color:"white", opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          </Paper>
        </Drawer>
      } */}
      <Box sx={{ flexGrow: 1}}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default AppMenuBar;