import {get_host} from "../Config"

export async function getConfigs(api_key){
  try {
    const url = get_host() + '/configs';
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'neum-api-key': api_key
      },
    };
    const response = await fetch(url, options)
    const data = await response.json()
    if (!response.ok){
      return({code:response.status, error:data.detail})
    }
    return data
  } catch (error) {
      console.error('Error fetching available configurations:', error);
      return({code:500, error:"Error fetching available configurations: " + error})
  }
}

export async function checkApiKey(jwt, env){
  var tempJSON = {
    "jwt" : jwt
  }
  try {
    const url = get_host() + '/api_key'
    const response = await fetch(url, { 
      method: "POST",
      headers: {
        'Content-Type' : 'application/json',
      },
      body: JSON.stringify(tempJSON)
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error creating token', error);
    throw error;
  }
}

export async function createPipeline(payload, api_key){
    // var validation = validatePipeline(nodes, edges)
    const url = get_host() + '/pipelines';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'neum-api-key': api_key
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok){
        return({code:response.status, error:data.detail})
      }
      var id = data.id
      return ({code:response.status, id:id})
    } catch (error) {
      console.error('Error creating pipeline:', error);
      return({code:500, error:"Error creating pipeline: " + error})
    }
  }

export async function updatePipeline(pipeline, api_key){
    const url = get_host() + '/pipelines/' + pipeline.id;
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'neum-api-key': api_key
      },
      body: JSON.stringify(pipeline),
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      var id = data.id
      return id
    } catch (error) {
      console.error('Error creating pipeline:', error);
      throw error;
    }
}

export async function syncData(id, api_key) {
    const url = get_host() + '/pipelines/' + id + "/trigger";
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'neum-api-key': api_key
      },
      body: JSON.stringify({"sync_type":"full"}), // defaulting to full from the ux
    };
    try {
      const response = await fetch(url, options);
      return "Pipeline " + id + " synced successfully!"
    } catch (error) {
      console.error('Error creating pipeline:', error);
      throw error;
    }
}  

export function findSink(nodes) {
  for (const node of nodes) {
    if (node.type == "sink") 
    {
        return node
    }
  }
}

export async function getSinknfo(pipelineID, api_key) {
  const url = get_host() + '/sink/' + pipelineID + "/info";
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'neum-api-key': api_key
    },
  };
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error getting sink info:', error);
    throw error;
  }
}

export async function enableRealtimeAsync(pipelineId, api_key, supabaseUrl, supabaseAnonKey, postgresSchema, postgresTable){
  const url = get_host() + '/pipelines/' + pipelineId + "/enable_realtime";
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'neum-api-key': api_key
    },

    body: JSON.stringify({
      "postgres_schema":postgresSchema,
      "postgres_table":postgresTable,
      "supabase_url":supabaseUrl,
      "supabase_anon_key":supabaseAnonKey
    }),
  };
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    if (!response.ok){
      return({code:response.status, error:data.detail})
    }
    return ({code:response.status, data:data})
  } catch (error) {
      console.error('Error enabling realtime for pipeline:', error);
      return({code:500, error:"Error enabling realtime for pipeline: " + error})
  }
}

export async function enableRealtimeAsyncS3(pipelineId, api_key, supabaseUrl, supabaseAnonKey, postgresSchema, postgresTable){
  const url = get_host() + '/pipelines/' + pipelineId + "/realtime/enable";
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'neum-api-key': api_key
    },
  };
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    if (!response.ok){
      return({code:response.status, error:data.detail})
    }
    return ({code:response.status, data:data})
  } catch (error) {
      console.error('Error enabling realtime for pipeline:', error);
      return({code:500, error:"Error enabling realtime for pipeline: " + error})
  }
}

export async function querySink(pipelineID, query, api_key, track) {
  const url = get_host() + '/pipelines/' + pipelineID + "/search";
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'neum-api-key': api_key
    },
    body: JSON.stringify({
      "query":query,
      "number_of_results":3,
      "collect_retrieval":track
    }),
  };
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error querying sink:', error);
    throw error;
  }
}

export async function getMyPipelines(api_key){
  try {
    const url = get_host()
    const response = await fetch(url + "/pipelines", { 
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'neum-api-key': api_key
      },
    })
    const data = await response.json()
    if (!response.ok){
      return({code:response.status, error:data.detail})
    }
    return {data:data['pipelines_v2']}
  } catch (error) {
    console.error('Error getting my pipelines:', error);
    return({code:500, error:"Error Getting pipelines: " + error})
  }
}

export async function getPipelineRuns(api_key, pipeline_id){
  const response = await fetch(get_host() + '/pipelines/' + pipeline_id +"/runs", { 
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'neum-api-key': api_key
    },
  })
  const data = await response.json()
  return data
}

export async function getPipeline(api_key, pipeline_id){
  try {
    const response = await fetch(get_host() + '/pipelines/' + pipeline_id, { 
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'neum-api-key': api_key
      },
    })
    const data = await response.json()
    if (!response.ok){
      return({code:response.status, error:data.detail})
    }
    return data
  } catch (error) {
    console.error('Error getting pipeline:', error);
    return({code:500, error:"Error getting pipeline: " + error})
  }
}

export async function getMyRetrievals(api_key, pipeline_id){
  try {
    const response = await fetch(get_host() + '/retrievals/' + pipeline_id, { 
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'neum-api-key': api_key
      },
    })
    const data = await response.json()
    if (!response.ok){
      return({code:response.status, error:data.detail})
    }
    return data
  } catch (error) {
    console.error('Error getting pipeline:', error);
    return({code:500, error:"Error getting pipeline: " + error})
  }
}

export async function deletePipeline(api_key, pipeline_id){
  try {
    const url = get_host() + '/pipelines/' + pipeline_id
    const response = await fetch(url, { 
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'neum-api-key': api_key
      },
    })
  } catch (error) {
    console.error('Error creating token', error);
    throw error;
  }
}

export async function provideRetrievalFeedback(api_key, pipeline_id, retrieval_id, status){
  const url = get_host() + '/retrievals/' + pipeline_id + "/" + retrieval_id;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'neum-api-key': api_key
    },
    body: JSON.stringify({
      "status": status
    }),
  };
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error querying sink:', error);
    throw error;
  }
}