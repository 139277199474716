import { Route, Routes } from "react-router-dom";
import Register from "./BuilderV2/auth/Register"
import Login from "./BuilderV2/auth/Login"
import AuthRoute from "./BuilderV2/auth/AuthRoute";
import Settings from "./BuilderV2/Settings";
import Home from "./BuilderV2/Home";
import PasswordReset from "./BuilderV2/auth/PasswordReset";
import UpdatePassword from "./BuilderV2/auth/UpdatePassword";
import { Analytics } from '@vercel/analytics/react';
import PipelineBuilderV2 from "./BuilderV2/PipelineBuilderV2";
import MyPipelines from "./BuilderV2/MyPipelines"
import PipelineDetailedStatus from "./BuilderV2/PipelineDetailedStatus"
import NotFound from "./BuilderV2/NotFound"
import Search from "./BuilderV2/Search";
import { isMobile } from 'react-device-detect';
import MobileVersion from "./BuilderV2/mobileVersion";
import Placeholder from "./BuilderV2/Placeholder";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  return (
    <div>
      <Analytics /> 
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Placeholder/>} />
          {/* <Route element={<AuthRoute />}>
            <Route path="/" element={isMobile ? <MobileVersion/> : <Home/>} />
            <Route path="/settings" element={isMobile ? <MobileVersion/> : <Settings />} />
            <Route path="/create" element={isMobile ? <MobileVersion/> : <PipelineBuilderV2 />} />
            <Route path="/create/:pipelineId" element={isMobile ? <MobileVersion/> : <PipelineBuilderV2 />} />
            <Route path="/mypipelines" element={isMobile ? <MobileVersion/> : <MyPipelines />} />
            <Route path="/mypipelines/:pipelineId" element={isMobile ? <MobileVersion/> : <PipelineDetailedStatus />}/>
            <Route path="/search" element={isMobile ? <MobileVersion/> : <Search />} />
          </Route> */}
          {/* <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/passwordreset" element={<PasswordReset />} /> */}
          {/* <Route path="/update-password" element={<UpdatePassword />} /> */}
          {/* <Route path='/upgrade' Component={() => {
            window.location.href = 'https://buy.stripe.com/14k28b1X4c7b45q147';
            return null;
          }} /> */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </div>
  )
}
export default App;
