import * as React from 'react';
import AppMenuBar from './AppMenuBar'
export default function NotFound() {
  return (
    <div>
      <AppMenuBar isloginpage={"true"}>
        <div className="min-h-screen bg-gray-900 p-10">
            <div className="pt-12 h-[100%] max-w-[90%] p-10 mx-auto">
              <div className="flex justify-between items-center pb-2">
                <div className="text-gray-300 text-3xl font-semibold pb-2">404 Not Found</div>
              </div>
            </div>
        </div>
      </AppMenuBar>
    </div>
  )
}