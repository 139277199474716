import { useState } from "react";
import { useAuth } from "./AuthProvider";
import AppMenuBar from "../AppMenuBar";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const PasswordReset = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { passwordReset } = useAuth();

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const { error } = await passwordReset(email);
        if (!error) {
          setMsg("Password reset has been sent to your email");
        }
        else{
          let prettyError = ""
          if (error.toString().indexOf("AuthApiError:") != -1){
            prettyError = error.toString().substring(error.toString().indexOf("AuthApiError:")+13)
          }
          setErrorMsg(`Error Creating Account - ${prettyError}`);
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };

  const handleEmailChange = (event) => {
    setEmail(event.target.value); 
  };

  return (
    <div>
      <AppMenuBar isloginpage={"true"}>
      <div className="min-h-screen bg-gray-900 p-20">
      <div className="h-content max-w-lg mx-auto my-auto p-10" style={{border:'2px solid #bfc4ca', borderRadius:'4px'}}>
        <div className="text-center text-gray-300 text-2xl font-semibold">Reset your Neum AI password</div>
            <br/><br/>
            <div>
            <form className="flex flex-col gap-y-4 p-2" onSubmit={handleSubmit}>
              <div id="email" className="grid grid-cols-1 gap-x-4 px-8">
              <TextField onChange={handleEmailChange} required label="Email address" variant="filled" sx={{backgroundColor:"white",borderRadius:"0.375rem"}}/>
              </div>
              {errorMsg && (
                <div
                  className="text-gray-300 px-8"
                  variant="danger"
                  onClose={() => setErrorMsg("")}
                  dismissibe="true">
                  ⚠️ {errorMsg}
                </div>
              )}
              <div className="text-center mt-2 px-8">
                <Button variant="contained" color="primary" type="submit" sx={{"&.Mui-disabled": {background: "#eaeaea", color: "#c0c0c0"} }} disabled={loading}>Send reset link</Button>
              </div>
            </form>
          </div>
          <div className="text-gray-300 text-center mt-2">
            Back to <Link sx={{ "&:hover": {color: "#1468d2",}}} color="#489de9" underline="none" href="/login">Login</Link>?
          </div>
        </div>
        </div>
      </AppMenuBar>
    </div>
  );
};

export default PasswordReset;