import { createContext, useContext, useEffect, useState } from "react";
import {supabase} from '../../supabase/client'
import { checkApiKey } from "../HelperFunctions";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

const loginGoogle = async () => await supabase.auth.signInWithOAuth({provider: 'google'})

const login = (email, password) =>
  supabase.auth.signInWithPassword({ email, password });

const signOut = async () => await supabase.auth.signOut();

const passwordReset = (email) =>
  supabase.auth.resetPasswordForEmail(email, {
    redirectTo: "http://dashboard.neum.ai/update-password"
  });

const updatePassword = (updatedPassword) =>
  supabase.auth.updateUser({ password: updatedPassword });

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null)
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();
      const { user: currentUser } = data;
      setUser(currentUser ?? null);
      setAuth(currentUser ? true : false);
      setLoading(false);
    };
    getUser();
    const getSession =  async () => {
      const {data} = await supabase.auth.getSession();
      const {session} = data
      setSession(session)
    }
    getSession();
    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        setAuth(false);
      } else if (event === "SIGNED_IN") {
        setSession(session)
        setUser(session.user);
        setAuth(true);
        let  key = await checkApiKey(session.access_token, process.env.NODE_ENV)
        setApiKey(key.api_key) 
        
      } else if (event === "SIGNED_OUT") {
        setAuth(false);
        setUser(null);
        setApiKey(null)
      }
    });
    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        session,
        apiKey,
        login,
        loginGoogle,
        signOut,
        passwordReset,
        updatePassword
      }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;